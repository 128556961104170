(function () {
  /*
  Survey Services
  */
  "use strict";

  angular.module("swurveys.common")
  	.factory("localStoreService", Handler)

  function Handler($localStorage) {

    function getItem(key) {
      try {
        var item = $localStorage[key];
        return item;
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    function setItem(key, item) {
      try {
        $localStorage[key] = item;
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    function removeItem(key) {
      try {
        $localStorage[key] = undefined;
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    return {
      getItem: getItem,
      setItem: setItem,
      removeItem: removeItem
    }
  }
}());
