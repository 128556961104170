(function () {
	"use strict";

	angular.module("swurveys.taker")
		.factory("modelCacheService", Handler)

	function Handler($q, utilsService, swurveyService, localGoogleFontService) {

        // Declare Database
        var db = new Dexie("ModelDatabase");
        db.version(1).stores({
            models: "&id,model,attempts,responses,WOTData"
        });

        function convertAssetsToBase64(assets) {
            var convertedAssets = assets.map(function(asset) {

                var outputFormat = 'image/jpeg';

                if (asset.src.match(/.png$/)) {
                    outputFormat = 'image/png';
                }
                return  {
                    id: asset.src,
                    base64: utilsService.convertImageElementToBase64(asset, {outputQuality: 0.7, outputFormat: outputFormat})
                };
            });
            return convertedAssets;
        }

        function convertCardImageLinksToBase64(model, assets) {
            model.cards = model.cards.map(function(card) {
                if (!card.img) return card;
                assets.forEach(function(asset){
                    if (card.img === asset.id) {
                        card.img = asset.base64;
                    }
                });
                return card
            });
        }

        function convertStyleSheetImageLinksToBase64(model, assets) {
            model.styles = model.styles.map(function(style){
                assets.forEach(function(asset) {
                    style = style.replace(asset.id, asset.base64);
                });
                return style;
            });
        }

        function saveResponseBody(id, body) {

            return db.models.where("id").equals(id).first().then(function(cachedModel) {
                if (!cachedModel) return;

                // Add response to the responses array 
                if (cachedModel.responses) {
                    cachedModel.responses.push(body);
                } else {
                    cachedModel.responses = [body];
                }

                // Update WOT data
                 if (cachedModel.WOTData) {
                    var WOT = cachedModel.WOTData;

                    WOT.TotalResponseCount =  WOT.TotalResponseCount + 1;

                    if (body.incrementAttempts) {
                        WOT.TotalAttemptedCount = WOT.TotalAttemptedCount + 1;
                    }

                    WOT.TimeMS = WOT.TimeMS + body.timeMS;

                    // Update answer counts
                    for (var answerKey in body.responses) {
                        if (WOT.Responses[answerKey]) {
                            var card = WOT.Responses[answerKey];
                            var choice = body.responses[answerKey];
                            if (card[choice]) {
                                card[choice] = card[choice] + 1;
                            } else {
                                card[choice] = 1;
                            }
                        }
                    }
                } else {
                    // Create initial WOT data
                    var responses = {};

                    for (var answerKey in body.responses) {
                        responses[answerKey] = responses[answerKey] || {};
                        var card = responses[answerKey];
                        var choice = body.responses[answerKey];
                        if (card[choice]) {
                            card[choice] = card[choice] + 1;
                        } else {
                            card[choice] = 1;
                        }
                    }

                    cachedModel.WOTData = {
                        SwurveyId: id,
                        TimeMS: body.timeMS,
                        TotalAttemptedCount: 1,
                        TotalResponseCount: body.incrementAttempts ? 1 : 0,
                        Responses: responses
                    }
                }

                return db.models.update(cachedModel.id, { responses: cachedModel.responses, WOTData: cachedModel.WOTData });
            });

        }

        function saveMetaData(id, ResponseId, data) {
            return db.models.where("id").equals(id).first().then(function(cachedModel) {
                if (!cachedModel) return;

                if (cachedModel.responses) {
                    var length = cachedModel.responses.length;
                    for (var i = 0; i < length; i++) {
                        var responseData = cachedModel.responses[i];
                        if (responseData.ResponseId === ResponseId) {
                            if(responseData.metadata) {
                                responseData.metadata = angular.extend(responseData.metadata, data);
                            } else {
                                responseData.metadata = data;
                            }
                            break;
                        }
                    }
                } else {
                    console.error('No responses found is cached model. Cant attach meta data');
                }
                
                return db.models.update(cachedModel.id, { responses: cachedModel.responses });
            });
        }

        function incrementAttemptCount(id) {
            db.models.where("id").equals(id).first().then(function(cachedModel) {
                if (!cachedModel) return;

                if (cachedModel.attempts) {
                    cachedModel.attempts = cachedModel.attempts + 1;
                } else {
                    cachedModel.attempts = 1;
                }
                return db.models.update(cachedModel.id, { attempts: cachedModel.attempts });
            });
        }

        function flushAllCachedResponses() {
            db.models.each(function(model) {
                if (model.responses && model.responses.length > 0) {
                    swurveyService.postResponseBatch(model.id, model.responses).then(function(data) {
                        console.log(data);
                        // remove cached responses from indexeddb
                        db.models.update(model.id, { responses: [] });
                    });
                }
            });
        }

		function saveModel (id, model, assets) {
            assets = convertAssetsToBase64(assets);
            convertCardImageLinksToBase64(model, assets);
            convertStyleSheetImageLinksToBase64(model, assets);
            return localGoogleFontService.extractFontUrls(model.styles).then(function(fontFaceStyles) {
                fontFaceStyles.forEach(function(style) {
                    model.styles.unshift(style);
                });
                return db.models.put({id: id, model: model}).then(function() {
                    return db.models.where("id").equals(id).first();
                }).catch(function (e) {
                    console.log("Error: " + (e.stack || e));
                });
            });
		}

        function getModel(id) {
           return db.models.where("id").equals(id).first();
        }

        function saveLatestWOTInfo(swurveyId) {
            swurveyService.getWOTData(swurveyId).then(function(response) {
                db.models.where("id").equals(swurveyId).first().then(function(cachedModel) {
                    if (!cachedModel) return;
                    return db.models.update(cachedModel.id, { WOTData: response.data });
                });
            });
        }

        function getWOTData(swurveyId) {
            var deferred = $q.defer();

            db.models.where("id").equals(swurveyId).first()
                .then(function(cachedModel) {
                    if (!cachedModel) {
                        deferred.resolve(null);
                    } else {
                        deferred.resolve(cachedModel.WOTData);
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    deferred.resolve(null);
                });

            return deferred.promise;
        }

		return {
            getModel: getModel,
            getWOTData: getWOTData,
			saveModel: saveModel,
            saveMetaData: saveMetaData,
            saveResponseBody: saveResponseBody,
            saveLatestWOTInfo: saveLatestWOTInfo,
            incrementAttemptCount: incrementAttemptCount,
            flushAllCachedResponses: flushAllCachedResponses
		};
	}
}());
