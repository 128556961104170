(function () {
	"use strict";

	angular.module("swurveys.common")
		.factory("fpsMeasurementService", Handler)

    //shameless stolen from http://www.quirksmode.org/rafp/
	function rafp() {
		var FPSlist = [],
			notes = [],
			currentNotes = [],
			maxDetections = 60,
			startTime,
			currentFPS,
			latestFPS,
			running = false,
			highestFPS = 0,
			support = !!window.requestAnimationFrame;

		function startNewCycle() {
			currentFPS = 0;
			currentNotes = [];
			startTime = new Date().getTime();
			requestAnimationFrame(cycle);
		}

		function cycle() {
			if (running) {
				var currentTime = new Date().getTime();
				if (currentTime - startTime < 1000) {
					currentFPS += 1;
					requestAnimationFrame(cycle);
				} else {
					latestFPS = currentFPS;
					highestFPS = Math.max(currentFPS, highestFPS);
					FPSlist.unshift(currentFPS);
					notes.unshift(currentNotes.join(', '));
					startNewCycle();
				}
			}
		}

		function chop() {
			var copy = [];
			for (var i = 0; (i < maxDetections && FPSlist[i]); i += 1) {
				copy.push(FPSlist[i]);
			}
			return copy;
		}

		return {
			start: function () {
				if (support) {
					running = true;
					startNewCycle();
				} else {
					this.getCurrentFPS = this.getAverageFPS = this.getMedianFPS = function () {
						return 'not supported';
					}
				}
			},
			end: function () {
				running = false;
			},
			supported: support,
			setPeriod: function (number) {
				maxDetections = number * 1 || 60;
			},
			addNote: function (note) {
				if (running) {
					currentNotes.push(note);
				}
			},
			getCurrentFPS: function () {
				if (running) {
					return latestFPS;
				} else {
					return 'not running';
				}
			},
			getAverageFPS: function () {
				var data = chop();
				var totalTime = 0;
				for (var i = 0; i < data.length; i += 1) {
					totalTime += data[i];
				}
				return Math.round(totalTime / data.length);
			},
			getMedianFPS: function () {
				var data = chop();
				data.sort(function (a, b) {
					return a - b;
				});
				if (data.length === 1) {
					return data[0];
				}
				var middle = data.length / 2;
				if (middle % 1) {
					return Math.round((data[middle - 0.5] + data[middle + 0.5]) / 2);
				} else {
					return data[middle];
				}
			},
			getFullDump: function () {
				var dump = [],
					obj;
				for (var i = FPSlist.length - 1; i >= 0; i -= 1) {
					obj = {};
					obj.fps = FPSlist[i];
					obj.notes = notes[i];
					dump.push(obj);
				}
				return dump;
			}
		}

	};


	function Handler() {

		var fps = rafp();
		fps.start();

		var getMedianFPS = function() {
			return fps.getMedianFPS();
		}
		var getAverageFPS = function() {
			return fps.getAverageFPS();
		}


		return {
			getAverageFPS : getAverageFPS,
			getMedianFPS : getMedianFPS,
			isSupported : function() { return fps.supported}
		}
	}
}());
