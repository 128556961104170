(function () {
    "use strict";

    angular.module("swurveys.common")
        .factory('fontSelector', Handler)

    function Handler () {

        var fontSelectorService = {
            primary: ".card-text",
            answers: ".hint-text",
            bold:    ".card-text b",
            button:  ".button-font, .wot-link",
            swot:    ".wot-custom-message, .WOTText, .WOTMainText"
        };

        return fontSelectorService;
    }

}());