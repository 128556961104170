(function () {
	"use strict";

	angular.module("swurveys.common")
	.factory("liveReloadService", Handler);

	function Handler(utilsService) {

		var client;

		//shamelessly stolen from http://docs.aws.amazon.com/iot/latest/developerguide/protocols.html
		function SigV4Utils() {}

		SigV4Utils.getSignatureKey = function (key, date, region, service) {
			var kDate = AWS.util.crypto.hmac('AWS4' + key, date, 'buffer');
			var kRegion = AWS.util.crypto.hmac(kDate, region, 'buffer');
			var kService = AWS.util.crypto.hmac(kRegion, service, 'buffer');
			var kCredentials = AWS.util.crypto.hmac(kService, 'aws4_request', 'buffer');
			return kCredentials;
		};

		SigV4Utils.getSignedUrl = function(host, region, credentials) {
			var datetime = AWS.util.date.iso8601(new Date()).replace(/[:\-]|\.\d{3}/g, '');
			var date = datetime.substr(0, 8);

			var method = 'GET';
			var protocol = 'wss';
			var uri = '/mqtt';
			var service = 'iotdevicegateway';
			var algorithm = 'AWS4-HMAC-SHA256';

			var credentialScope = date + '/' + region + '/' + service + '/' + 'aws4_request';
			var canonicalQuerystring = 'X-Amz-Algorithm=' + algorithm;
			canonicalQuerystring += '&X-Amz-Credential=' + encodeURIComponent(credentials.accessKeyId + '/' + credentialScope);
			canonicalQuerystring += '&X-Amz-Date=' + datetime;
			canonicalQuerystring += '&X-Amz-SignedHeaders=host';

			var canonicalHeaders = 'host:' + host + '\n';
			var payloadHash = AWS.util.crypto.sha256('', 'hex')
			var canonicalRequest = method + '\n' + uri + '\n' + canonicalQuerystring + '\n' + canonicalHeaders + '\nhost\n' + payloadHash;

			var stringToSign = algorithm + '\n' + datetime + '\n' + credentialScope + '\n' + AWS.util.crypto.sha256(canonicalRequest, 'hex');
			var signingKey = SigV4Utils.getSignatureKey(credentials.secretAccessKey, date, region, service);
			var signature = AWS.util.crypto.hmac(signingKey, stringToSign, 'hex');

			canonicalQuerystring += '&X-Amz-Signature=' + signature;
			if (credentials.sessionToken) {
				canonicalQuerystring += '&X-Amz-Security-Token=' + encodeURIComponent(credentials.sessionToken);
			}

			var requestUrl = protocol + '://' + host + uri + '?' + canonicalQuerystring;
			return requestUrl;
		};



		var init = function(credentials, clientId, topic, reloadCallback) {
				var wsURL = SigV4Utils.getSignedUrl('a3fh4kvqh2zv0s-ats.iot.us-east-1.amazonaws.com','us-east-1',credentials);

                console.log("connection: ", clientId, topic)
				client = new Paho.MQTT.Client(wsURL, clientId);
				var connectOptions = {
					onSuccess: function () {
						console.log("Subscribe to topic: ", topic);
						client.subscribe(topic);

					},
					useSSL: true,
					timeout: 3,
					mqttVersion: 4,
					onFailure: function (err) {
						console.error('connect failed', err);
					}
				};
				client.connect(connectOptions);
				client.onMessageArrived = function (msg) {

					try {
						reloadCallback(JSON.parse(msg.payloadString));
					} catch (e) {
						console.log("error! " + e);
					}
				};
				client.onConnectionLost = function(err){
					console.log("Conn lost!", err);
				};

				var disconnect = function() {
					client.disconnect();
				}
				return disconnect;
		};

		return {
			init : init
		};
	}
}());
