(function () {
	"use strict";

	angular.module("swurveys.taker")
		.factory("tutorialCardsService", Handler)

	function Handler($timeout, $q, analyticsService, localStoreService) {

		var cards = [
			{
				type: "content",
				text: "<div class='text-center extra-mile-font text-size-60'>GET YOUR MICROGAME ON!</div><br><br><div class='text-center'>Swipe to begin</div>",
				positionClass: 'left',
				isTutorialCard: true
      },
			{
				type: "binary",
				text: "<div class='text-center'>For question cards, swipe in the direction of your answer.<br><br>Do you understand?</div>",
				left: {
					"choice": "No"
				},
				right: {
					"choice": "Yes"
				},
				positionClass: 'right',
				isSecondTutorialCard: true,
				isTutorialCard: true
      },
			{
				type: "content",
				text: "<div class='text-center'>MICROGAMES ARE ALL SWIPE NO TYPE SURVEYS!<br><br> Swipe this card any direction to proceed.<div>",
				positionClass: 'left',
				isTutorialCard: true
      }
    ]

		function inserttutorialCardsService (model) {
			for(var i = 0; i < cards.length; i++) {
				model.cards.push(cards[i]);
			}
		}

		function removetutorialCardsService (model) {
			for(var i = model.cards.length - 1; i >= 0; i--) {
				if (model.cards[i] && model.cards[i].isTutorialCard)
					model.cards.splice(i, 1);
			}
		}

		function get () {
			return cards;
		}

		function size () {
			return cards.length;
		}

		function runTutorial (model, alwaysRunTutorial) {

			if(localStoreService.getItem("tutorialCardsServiceV1") && !alwaysRunTutorial) {

				analyticsService.trackEvent('TutorialSkipped', null, {
                   nonInteraction: true //SwurveySkipped should not affect bounce rate
                });
				return;
			}

			analyticsService.trackEvent('TutorialStarted', null, {
                 nonInteraction: true //SwurveyStarted should not affect bounce rate
              });

			localStoreService.setItem("tutorialCardsServiceV1", true);

			inserttutorialCardsService(model);

		}

		return {
			_inserttutorialCardsService: inserttutorialCardsService, // EXPOSED FOR TESTING
			_removetutorialCardsService: removetutorialCardsService, // EXPOSED FOR TESTING
			runTutorial: runTutorial,
			size: size,
			get: get
		};
	}
}());
