(function () {

	/*
	Card Service
	*/

	"use strict";

	angular.module("swurveys.taker")
	.factory("cardService", Handler);

	function Handler(utilsService, analyticsService, sliderService, currentSwurveyService, tutorialCardsService, $routeParams) {

		var firstCardHasBeenSwiped = false;
		var ownerId = currentSwurveyService.getSwurveyOwnerId();

		function getScoredActionCard(model, cumulativeScore) {
			//copy so we don't touch the model incase something else relies on its order
			var actionCards = model.actionCards.slice();
			// sort our action cards by their min score
			actionCards.sort(function(a,b){ return b.minScore - a.minScore });

			//strip out action cards that have too low of a score
			actionCards = actionCards.filter(function(card){ return cumulativeScore >= card.minScore});

			var card = actionCards[0]
			if(card && card.id) {
				return card.id;
			}
			return undefined;
		}

		function createSliderAnswerCard(answeredCard) {
			var state = sliderService.getState();
			answeredCard.answer = answeredCard[state.selectedValue];
			if(answeredCard.answer) {
				answeredCard.answer.direction = state.selectedValue;
			}
		}

		function createAnswerCard(answeredCard, swipedDirection) {
			answeredCard.answer = answeredCard[swipedDirection];
			if(answeredCard.answer) {
				answeredCard.answer.direction = swipedDirection;
			}
		}

		function pushAnswerCard(swipedDirection, currentCard, answeredCardStack) {

			var answeredCard = currentCard.shift();

			var choice;

			if(answeredCard.type == "slider") {
				createSliderAnswerCard(answeredCard);
			} else if(utilsService.getQuestionCardTypes().indexOf(answeredCard.type) >= 0){
				//Ensure it's a question card.
				createAnswerCard(answeredCard, swipedDirection);
			}

			if(answeredCard.answer) {
				choice = answeredCard.answer.choice;
			}

			//We only care to collect the answer here if it's not the tutorial
			if(answeredCard && !answeredCard.isTutorialCard) {
				answeredCardStack.push(answeredCard);
			}

			//Our answered stack already has the card we just answered and only has customer cards in it
			//so its length is the cardinality we want
			analyticsService.trackCardSwipe(currentSwurveyService.getSwurveyId(), answeredCardStack.length, ownerId, choice);
			return answeredCard;
		}

		function moveToCard(model, type) {
			var card;
			do {
				card = model.cards.pop();
			} while (card.type != type);

			return card;
		}

		function addCommentCard(model) {
			var text = utilsService.defaultText.extendedFeedback;
			if (model.options.extendedFeedback.text) {
				text = model.options.extendedFeedback.text;
			}
			var commentCard = {
				id: "extendedFeedbackCard",
				type: "extendedFeedback",
				text: text,
				locked: true
			};

			model.cards.unshift(commentCard);
		}

		function addLeadGenCard(model) {
			var text = utilsService.defaultText.leadGen;
			if (model.options.leadgen.text) {
				text = model.options.leadgen.text;
			}
			var leadgenCard = {
				id: "leadgenCard",
				type: "leadgen",
				text: text,
				locked: true
			}

			model.cards.unshift(leadgenCard);
		}

		function initCardStack(model, cardStackOptions) {

			var currentCard = [];
			var currentCardIndex = 0;
			var answeredCardStack = [];
			var cumulativeScore = 0; // keep track of a user's score
			var allCards = model.cards.slice();
			var responsesSent = false;

			// If none of the cards have noSWOT set to false, then
			// we disable the SWOT card.
			var disableSWOT = _.every(model.cards, function(card) {
				return card.noSWOT === undefined || card.noSWOT === true;
			});

			// Process cards
			model.cards.reverse();

			// ******************************************************************************************
			// HANDLE ADDING THE COMMENT CARD 
			// ******************************************************************************************
			var commentCardIsEnabled = model.options && model.options.extendedFeedback 
													 && model.options.extendedFeedback.enabled;
			var commentCardHasBeenSeen = false;

			// Add comment card
			if (commentCardIsEnabled)
				addCommentCard(model);

			// ******************************************************************************************
			// HANDLE ADDING THE LEAD GEN CARD 
			// ******************************************************************************************
			var leadGenIsEnabled = model.options && model.options.leadgen 
												 && model.options.leadgen.enabled;
			var leadGenCardHasBeenSeen = false;

			// Add Lead Gen card
			if (leadGenIsEnabled)
				addLeadGenCard(model);

			// ******************************************************************************************
			// HANDLE ADDING THE WOT CARD 
			// ******************************************************************************************
			var WOTCard = {
				type: "SwurveyWOT",
				text: "SEE WHAT OTHERS THINK!",
				locked: true
			}

			model.cards.unshift(WOTCard);

			if (cardStackOptions.runTutorial.runIfFirstSwurvey) {
				tutorialCardsService.runTutorial(model, cardStackOptions.runTutorial.alwaysRun);
			}

			var next = function (swipedDirection) {
				var answeredCard;
				if(swipedDirection)
					answeredCard = pushAnswerCard(swipedDirection, currentCard, answeredCardStack);
				if(answeredCard && answeredCard.answer && answeredCard.answer.score) {
					cumulativeScore = cumulativeScore + answeredCard.answer.score;
				}

				var answeredLength = answeredCardStack.length;
				var deckSize = model.cards.length;

				var newCard;
				//A swiped direction(or choice) may have a 'next' member. This represents where to skip in the Swurvey 
				//It is either the ID of a later card, OR the keyword 'end' which means to just end
				var nextId;
				if(answeredCard && model.options && model.options.branching && model.options.branching.enabled)
				{
					if(answeredCard.next)
					{
						//We have content card that is going to skip ahead
						nextId = answeredCard.next;
					}
					if(answeredCard.answer && answeredCard.answer.next)
					{
						//OR the choice we answered will skip ahead.
						nextId = answeredCard.answer.next;
					}
				}
                var nextIdEnd = nextId && nextId.startsWith && nextId.startsWith('end');
				if (nextIdEnd || deckSize === 1) {
                    var nextIdParam = (nextId && nextId.split) ? nextId.split(':')[1] : null;

                    if(answeredCard && answeredCard.opengraph && answeredCard.type == "content" && answeredCard.opengraph.enabled) {
						// We are overriding the share variables, let's just manipulate the in-memory model
						
						model.options.opengraph = answeredCard.opengraph;
					}

					// Skip some cards depending on 'end' parameter
					if (nextIdParam==='comments') {
                    	// just do nothing to process to comments
					} else if (nextIdParam==='leadgen') {
                        commentCardHasBeenSeen = true; // skip comments
					}  else if (nextIdParam==='lg') { // leadgen, then action card
                        commentCardHasBeenSeen = true; // skip comments
						model.options.action.actionCardId  = (nextId && nextId.split) ? nextId.split(':')[2] : null; // take third param
					} else if (nextIdParam) { // action card id
                        commentCardHasBeenSeen = true;
                        leadGenCardHasBeenSeen = true;
                    }

					if (commentCardIsEnabled && !commentCardHasBeenSeen) {
						commentCardHasBeenSeen = true;
						newCard = moveToCard(model, 'extendedFeedback');
					} else if (leadGenIsEnabled && !leadGenCardHasBeenSeen) {
						leadGenCardHasBeenSeen = true;
						newCard = moveToCard(model, 'leadgen');
					} else {
						//If the ID is 'end', then we pretend we just swiped the last card
						if (!disableSWOT) {
							newCard = WOTCard;
						}
						model.cards = []; //just get rid of the rest of the stack.
						var actionCardId = nextIdParam || model.options.action.actionCardId;
						if(model.options.action.scored) {
							actionCardId = getScoredActionCard(model, cumulativeScore);
						}
						cardStackOptions.lastCardSwiped(currentCard, allCards, answeredCardStack, disableSWOT, actionCardId);
					}
				}
				else if (nextId) {
					//Else, we assume it's the ID of another card.
					//Pop off the model until we get to that card.
					do {
						newCard = model.cards.pop();
					} while (newCard.id != nextId)
				}
				else{
					newCard = model.cards.pop();
				}

				if(newCard) {
					if(newCard.type === 'slider') {
						sliderService.initializeSlider(newCard);
					} else if (newCard.type === 'leadgen') {
						leadGenCardHasBeenSeen = true;
					} else if (newCard.type === 'extendedFeedback') {
						commentCardHasBeenSeen = true;
					}
					currentCard.push(newCard);
				}

				//The user is staring at the Cover Card
				if(currentCardIndex == 0 && newCard && !newCard.isTutorialCard) {
					cardStackOptions.coverCardShown(firstCardHasBeenSwiped);
				}

				//The user is staring at the second card, either of the tutorial or of the Swurvey itself
				if(!firstCardHasBeenSwiped && (currentCardIndex == 1 || (newCard && newCard.isSecondTutorialCard))) {
					cardStackOptions.firstCardSwiped();
					firstCardHasBeenSwiped = true;
				}

				//Our cardinality is only affected by user-created Swurvey cards
				if(newCard && !newCard.isTutorialCard)
					currentCardIndex++;
					
				// We've already pulled the next card off the stack, so let's
				// add it back for this calculation
				var noMoreQuestionCards = (!newCard && model.cards.length == 0) || model.cards.concat([newCard]).reduce(function(accum, card){ 
					return accum && card && (utilsService.getQuestionCardTypes().indexOf(card.type) == -1)}, true);
				if(noMoreQuestionCards){
					sendResponses();
				}

			}; //END OF NEXT FUNCTION

			function sendResponses() {
				if(!responsesSent) {
					cardStackOptions.postResponses(allCards, answeredCardStack, disableSWOT);
					responsesSent = true;
				}
			}

			cardStackOptions.initComplete();

			// Remove Cover Card
			if (model.removeCoverCard)
				model.cards.pop();

			if(model.options.geoRedirectAtStart && model.options.geoRedirectAtStart.enabled) {
				// Geo redirect is enabled
				// Let's not show the first card yet, because we may want to redirect
				
			}
			else {
				// Geo redirect is not enabled, so show the first card
				next();
			}

			function getCurrentCards() {
				return currentCard;
			}

			function getCurrentCardIndex() {
				return currentCardIndex;
			}

			function isResponsesSent() {
				return responsesSent;
			}

			var cardStack = {
				currentCards: getCurrentCards,
				currentCardIndex: getCurrentCardIndex,
				swotAnimationComplete: false,
				next: next,
				isResponsesSent: isResponsesSent,
				sendResponses: sendResponses,
				WOT: undefined
			};

			return cardStack;
		} //END CARD STACK INIT

		return {
			initCardStack: initCardStack,
		}
	}

}());
