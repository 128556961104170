(function () {
	"use strict";

	angular.module("swurveys.taker")
		.factory("currentSwurveyService", Handler);

	function Handler(utilsService, $q, $routeParams, $route, swurveyService, preloaderService, 
						liveReloadService, swurveysStageConfig, analyticsService, 
						localStoreService, modelCacheService, networkService) {

		var id = $routeParams.swurveyId || TEST_API;

		var cachedModelCards;
		var cachedModelOptions;

		var shortUrl;
		var state;

		var ownerId;
		var userId;

		function initLiveReload() {
			AWS.config.region = 'us-east-1';
			var credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId: "us-east-1:b872b759-d2c9-4347-b8dc-ec18ec82af83"
			});
			credentials.get(function(err) {
				if(err) return console.log(err);

				var stage = swurveysStageConfig.getConfigPropertyValue("api_endpoint").split("/").pop();
				var topic = stage + "/users/" + userId + "/swurveys/" + id + "/model";
				liveReloadService.init(credentials, userId+new Date().getTime(), topic, function(msg) {
					var newModel = msg.payload;

					var newModelCards = JSON.stringify(newModel.cards);
					var newModelOptions = JSON.stringify(newModel.options);

					if (cachedModelCards !== newModelCards || cachedModelOptions !== newModelOptions) {
						$route.reload();
					}
					utilsService.reloadStyles(newModel.styles);
				});
			});
		}

		function cacheSwurveyData(model) {
			cachedModelCards = JSON.stringify(model.cards);
			cachedModelOptions = JSON.stringify(model.options);

			shortUrl =	model.shortUrl;
			state 	 =	model.state;
			ownerId  =	model.ownerId;
			userId   =	model.userId;
		}

		function loadModelFromCache(cachedModel, getModelSuccess, getModelError, getModelUpdate) {
			cacheSwurveyData(cachedModel.model);

			swurveyService.incrementAttemptsOnResponsePost();

			console.log(cachedModel);
			// Increment the attemps only if app is offline
			networkService.isOnline().then(function(online) {
				if (!online) {
					modelCacheService.incrementAttemptCount(cachedModel.id);
				} else {
					modelCacheService.saveLatestWOTInfo(cachedModel.id);
				}
			});

			//Swurvey is closed or out of credits
			if(state == "Closed") {
				$(".hide-when-loaded")
					.addClass("hide");
				return getModelUpdate({ id: 0, state: state });
			}

			getModelUpdate({
				id: 0,
				state: state,
				shortUrl: shortUrl,
				deckSize: cachedModel.model.cards.length
			});

			// Loads in styles
			utilsService.reloadStyles(cachedModel.model.styles);
			utilsService.applyStyleOverrides(cachedModel.model);

			getModelSuccess(cachedModel.model);
		}

		function loadModelFromServer(getModelSuccess, getModelError, getModelUpdate) {

			var offlineMode = $routeParams.offline;

			swurveyService.getModel(id, function (err, resp) {

				if(err) return getModelError(err.status);

				state = resp.headers("x-swv-state");

				if (state === 'Closed') {
					$(".hide-when-loaded")
				    	.addClass("hide");
					return getModelUpdate({ id: 0, state: state });
				}

				var model = resp ? resp.data : null;

				if(!model) return getModelError(501);

				model.shortUrl 	=  resp.headers("x-swv-short-url");
				model.state 	=  state;
				model.ownerId 	=  resp.headers("x-swv-owner");
				model.userId 	=  model.ownerId.substr(5);

				cacheSwurveyData(model);

				if(state == "New") {
					initLiveReload();
		    	}

				getModelUpdate({
					id: 0,
					state: state,
					shortUrl: shortUrl,
					deckSize: model.cards.length
				});

				//Should we deny the Swurvey based upon cookies?
				if(state == "Published" && model.options && model.options.singleResponse && model.options.singleResponse.useCookie) {
					var key = "swv:" + getSwurveyId() + ":responseCount";
					var responseCount = localStoreService.getItem(key);
					if (responseCount > 0) {
						//Just bail out now
						$(".hide-when-loaded")
							.addClass("hide");
						return getModelUpdate({ id: 0, state: "AlreadyResponded" });
					}
				}

				// Loads in styles
				utilsService.reloadStyles(model.styles);
				utilsService.applyStyleOverrides(model);

				var modelCopy = angular.copy(model);

				var priorityAssets = [];
				var secondaryAssets = [];

				// Check cards for images
				model.cards.forEach(function (card, index) {
					if(card.img) {
						if (index === 0) {
							priorityAssets.push(card.img);
						} else {
							secondaryAssets.push(card.img);
						}
					}
				});

				// Check css styles for images
				model.styles.forEach(function (style) {
					var backgroundImgRegex = new RegExp('^\.viewer');
					var imgLinkRegex = new RegExp('(https://app(-(alpha|preprod))?\.swurveys\.com/[a-zA-Z0-9-:/\.]*)', 'ig');
					var match = style.match(imgLinkRegex);
					if (match) {
						if (style.match(backgroundImgRegex)) {
							priorityAssets.push(match[0]);
						} else {
							secondaryAssets.push(match[0]);
						}
					}
				});

				analyticsService.mark("PreloadImagesStart");

				// Stage 2 notify image here

				// ######## Preload Primary Assets ########

				var preloadedPrimaryAssets = preloaderService.preloadImages(priorityAssets);
					
				preloadedPrimaryAssets.then(function resolved() {
					analyticsService.mark("PreloadImagesPriorityFinished");
					getModelSuccess(model);
				})
				.catch(function() {
					//If an image does not exist, we still want to start the Swurvey..but we also want to track that this occurred
					analyticsService.trackEvent('ImageFailedToLoad', id);
					getModelSuccess(model);
				});

				// ######## Preload Secondary Assets ########

				var preloadedSecondaryAssets = preloaderService.preloadImages(secondaryAssets);
					
				preloadedSecondaryAssets.then(function () {
					analyticsService.mark("PreloadImagesSecondaryFinished");
				})
				.catch(function() {
					analyticsService.trackEvent('ImageFailedToLoad', id);
				});

				// ######## Save Model after all assets are loaded ########

				$q.all([preloadedPrimaryAssets, preloadedSecondaryAssets]).then(function(assets) {
					// Cache model if offline mode is enabled
					if (offlineMode) {
						var allAssets = assets[0].concat(assets[1]);
						modelCacheService.saveModel(id, modelCopy, allAssets).then(function(model) {
							console.log(model);
						});
					}
				}).catch(function (error) {
					console.log(error);
				});
			});
		};

		function getCurrentModel(getModelSuccess, getModelError, getModelUpdate) {
			id = $routeParams.swurveyId;
			if(!id) return getModelError(404);
			
			modelCacheService.getModel(id).then(function(cachedModel) {
				if (cachedModel) {
					return loadModelFromCache(cachedModel, getModelSuccess, getModelError, getModelUpdate);
				} else {
					return loadModelFromServer(getModelSuccess, getModelError, getModelUpdate);
				}
			}).catch(function(error) {
				console.log(error);
				return loadModelFromServer(getModelSuccess, getModelError, getModelUpdate);
			});
		};

		function getSwurveyState() {
			return state;
		};

		function getSwurveyShortURL() {
			return shortUrl;
		};

		function getSwurveyId() {
			return id;
		}

		function getSwurveyOwnerId() {
			return ownerId;
		};

		return {
			getSwurveyId: getSwurveyId,
			getCurrentModel: getCurrentModel,
			getSwurveyState: getSwurveyState,
 			getSwurveyShortURL: getSwurveyShortURL,
 			getSwurveyOwnerId : getSwurveyOwnerId
		}

	}
}());
