(function () {
	"use strict";

	angular.module("swurveys.common")
		.factory("analyticsService", Handler);

	function Handler($location, $rootElement, localStoreService, fpsMeasurementService) {

		// http://cookbook.angularjs.de/recipes/get-current-app-name/
		var app = $rootElement.attr('ng-app').search('maker') !== -1 ? 'Maker' : 'Taker';

		// Prevent errors from occuring when analytics scripts aren't loaded
		function checkAvailability() {
			if (window.twttr === undefined) window.twttr = function () {};
			if (window.fbq === undefined) window.fbq = function () {};
			if (window.ga === undefined) window.ga = function () {};
			if (window._hsq == undefined) window._hsq = [];
		}

		// Dont report analytics in alpha or local enviroment or from swurveys staff
		function isDevEnv() {
			var host = $location.host();

			if (host.search('alpha') !== -1 || host.search('localhost') !== -1 || host.search('c9users.io') !== -1) {
				return true;
			}
			return localStoreService.getItem("analyticsOptOut");
		}

		function trackEvent(eventName, swurveyId, options, ownerId) {

				if (isDevEnv()) return;
				checkAvailability();

                var fbParams  =  { swurveyId: swurveyId };
                if(ownerId)
                {
                	fbParams.ownerId = ownerId;
                }
				fbq('trackCustom', eventName, fbParams);

				//These are emitted from the backend
				var eventsToSkipGA = ["CreateSwurvey", "PublishSwurvey", "PurchaseCredits", "AssociateCreditCard"];

				if(eventsToSkipGA.indexOf(eventName) < 0)
			    {
				  ga('send', 'event', app, eventName, swurveyId, options);
			    }

				//twitter SwurveyStarted event
				//twttr.conversion.trackPid('nvdnm', { tw_sale_amount: 0, tw_order_quantity: 0 });

				//twttr.conversion.trackPid('nvdno', { tw_sale_amount: 0, tw_order_quantity: 0 });

				//CompleteRegistration Signup event
				//twttr.conversion.trackPid('nv8mk', { tw_sale_amount: 0, tw_order_quantity: 0 });
		}

		function trackPurchase(product_name, count, cost) {
				if (isDevEnv()) return;
				checkAvailability();

				fbq('track', "Purchase", {
					value : cost,
					currency: "USD",
					content_name : product_name,
					num_items : count
				});

		}

		function trackCardSwipe(swurveyId, cardIndex, ownerId, choice) {

				if (isDevEnv()) return;
				checkAvailability();

				fbq('trackCustom', "CardSwipe", {
					swurveyId: swurveyId,
					cardIndex: cardIndex,
					ownerId : ownerId,
					choice : choice
				});

				ga('send', 'event', app, "CardSwipe", swurveyId, cardIndex);
				if(fpsMeasurementService.isSupported())
				{
				  ga('send', 'event', app, "MedianFPS", swurveyId, fpsMeasurementService.getMedianFPS());
				  ga('send', 'event', app, "AverageFPS", swurveyId, fpsMeasurementService.getAverageFPS());
				}

		}

		function trackTiming(variable, timeMS, label) {

			if (isDevEnv()) return;
			checkAvailability();
			ga('send', 'timing', app, variable, timeMS, label);

		}


        var startTime = new Date().getTime();
		//mark is to track when certain things happen
		//right now it's for instrumenting our loading sequence
		function mark(name) {
			//Safari doesn't offer this API
			if(window.performance && window.performance.mark)
			{
     			window.performance.mark(name);
			}

			//We're just going to call the timing based from when the controller started for now
			trackTiming(name, new Date().getTime() - startTime);
			if(isDevEnv())
			{
				//This puts events in the timeline. Only do this for dev machines
			    console.timeStamp(name);
			}
		}

		function trackCustomEvent(eventName, swurveyId, action) {

			if (isDevEnv()) return;
			checkAvailability();

			fbq('trackCustom', eventName, {
				swurveyId: swurveyId,
				text: action.text,
				link: action.link
			});
			ga('send', 'event', app, eventName, swurveyId);
		}

		function trackException(exDescription) {

			if (isDevEnv()) return;
			checkAvailability();

			ga('send', 'exception', {
				'exDescription': exDescription
			});
		}

		function trackPage(path) {

			if (isDevEnv()) return;
			checkAvailability();

			ga('set', 'page', path);
			ga('send', 'pageview');
			 _hsq.push(['setPath', path]);
			 _hsq.push(['trackPageView']);
		}

		function setUserId(userId, email) {

			if (isDevEnv()) return;
			checkAvailability();

			//Associate our cognito id with the GA session
			ga('set', 'userId', userId); // Set the user ID using signed-in user_id.

			//hubspot
			_hsq.push(["identify", {
				email: email,
				id: userId
			}]);
			 _hsq.push(['trackPageView']);


			if (typeof trackJs != 'undefined') {
				trackJs.configure({
					userId: userId
				});
            }


		}

		function setUserType(userType) {

			if (isDevEnv()) return;
			checkAvailability();

			ga('set', 'dimension1', userType);

			//DesignerLogin
			//twttr.conversion.trackPid('nv8ml', { tw_sale_amount: 0, tw_order_quantity: 0 });
		}


		//Add an additional FB pixel to the FB SDK
		function addCustomerFacebookPixel(pixelId) {
			if (isDevEnv()) return;
			checkAvailability();
			fbq('init', pixelId);

		}
		function optOutCurrentUser() {
			localStoreService.setItem("analyticsOptOut", true);
			console.log("Disabling Tracking For This User");
		}

		/**
		 * Converts the number of submissions for a single answer to a percentage
		 * @param answers Number of submissions for a given answer
		 * @param total   Total number of submissions
		 * @returns {number}  Percentage (double)
		 */
		function getAsPercent (answers, total) {
			var num = (answers / total) * 100;
			return +(Math.round(num + "e+2") + "e-2") || 0;
		}

		/**
		 * Converts the number of submissions for a single answer to a raw number
		 * @param answers Number of submissions for a given answer
		 * @param total   Total number of submissions
		 * @returns {number}  Number (double)
		 */
		function getAsRawNumber (percent, total) {
			var num = (percent * total) / 100;
			return +(Math.round(num + "e+2") + "e-2");
		}

		return {
			_isDevEnv: isDevEnv,										// EXPOSED FOR TESTING
			_checkAvailability: checkAvailability,  // EXPOSED FOR TESTING
			getAsPercent: getAsPercent,
			getAsRawNumber: getAsRawNumber,
			optOutCurrentUser : optOutCurrentUser,
			trackPage: trackPage,
			trackEvent: trackEvent,
			trackPurchase: trackPurchase,
			setUserId: setUserId,
			setUserType: setUserType,
			trackException: trackException,
			trackCustomEvent: trackCustomEvent,
			trackTiming : trackTiming,
			trackCardSwipe : trackCardSwipe,
			addCustomerFacebookPixel : addCustomerFacebookPixel,
			mark : mark
		};
	}
}());
