
(function () {
	"use strict";

	angular.module("swurveys.common")
		.factory("sliderService", Handler)

	function Handler($timeout, swipeService, utilsService) {

    var autoswipe;
		var autoswipeTimeout;
		var defaultSliderState = {
			currentValue: 0,
			selectedValue: "one",
			locked: true
		};
		var currentCard;

		var values = ["one", "two", "three", "four", "five"];

		var sliderDemoHasBeenSeen = false;

		var slider = {
			options: {
				start: function (event, ui) {
					slider.state.locked = true;
				},
				stop: function (event, ui) {
					slider.state.locked = false;
					if(autoswipe)
					{
						$timeout.cancel(autoswipeTimeout);
						autoswipeTimeout = $timeout(function(){
							return swipeService.swipeRight();
						}, 1000);
						return;
					}
					$timeout(function(){
						//We're not in a digest cycle! so use $timeout
					  slider.doneSliding = true;
					});
					if(pepContainer){
						pepContainer.toggle(true); // TODO remove global var from service
					}
				}
			},
			state: JSON.parse(JSON.stringify(defaultSliderState)),
			update: function () {
				$timeout.cancel(autoswipeTimeout);
				sliderUpdate();
			},
			getStepCount: function() {
			   var count = (utilsService.getOrderedValidSliderChoices(currentCard).length - 1)  * 100;	
			   return Number(count);
			},
			doneSliding: false,
			animating: false
		};

		function normalizeSliderState() {
			slider.state = JSON.parse(JSON.stringify(defaultSliderState));
		}

		function sliderUpdate() {
			var valueIndex = Math.floor(slider.state.currentValue / 100);

			var chosenValue = values[valueIndex];

			slider.state.selectedValue = chosenValue;
		}

		function animateSlider(target, increment, maxSteps) {

			if(slider.state.currentValue >= target && increment > 0) {
				// we're at the top, switch direction
				return $timeout(function () {

					animateSlider(maxSteps/2, -increment, maxSteps);

				}, 100);
			}
			
			//We're going down and have reached the moddle
			// we're done animating
			if(slider.state.currentValue < maxSteps/2 && increment < 0) {
				slider.animating = false;
				return;
			}


			$timeout(function () {
				slider.state.currentValue += increment;

				sliderUpdate();

				animateSlider(target, increment, maxSteps);
			}, 11.5);
		}

		function initializeSlider(card) {
			currentCard = card;
			normalizeSliderState();
			
			values = utilsService.getOrderedValidSliderChoices(currentCard);
	
			var maxSteps = (values.length - 1) * 100;

	    if(!sliderDemoHasBeenSeen) {

				sliderDemoHasBeenSeen = true;

				$timeout(function () {
					slider.animating = true;
					animateSlider(maxSteps, 8, maxSteps);
				}, 2000);

	    } else {

				slider.doneSliding = false;

		    slider.state.currentValue = maxSteps /2;
		    sliderUpdate();

	    }

		}

		function getSlider() {
			return slider;
		}

		function getState() {
			return slider.state;
		}
		var enableAutoswipe = function() {
			autoswipe = true;
		}

		return {
			getSlider: getSlider,
			getState: getState,
			normalizeSliderState: normalizeSliderState,
			initializeSlider: initializeSlider,
			enableAutoswipe : enableAutoswipe
		};
	}
}());
