(function () {
	/*
	Swurvey Services
	*/
	"use strict";

	angular.module("swurveys.common")
		.factory("loadingBannerService", Handler)

	function Handler() {
    var loadingBannerService = {};

    loadingBannerService.show = function () {
        $('#loading-banner').slideDown();
    }

    loadingBannerService.hide = function () {
        $('#loading-banner').hide();
    }

    return loadingBannerService;

	}
}());
