(function () {
	/*
	Swurvey Services
	*/
	"use strict";

	angular.module("swurveys.common")
		.factory("utilsService", Handler)

	function Handler(swurveysStageConfig) {
    var utilsService = {};

    utilsService.rotate = function ($el, deg, speed) {
    	$el.css({
    		"transition": (speed || 0) + "s",
    		"-webkit-transform": "rotate(" + deg + "deg)",
    		"-moz-transform": "rotate(" + deg + "deg)",
    		"-ms-transform": "rotate(" + deg + "deg)",
    		"-o-transform": "rotate(" + deg + "deg)",
    		"transform": "rotate(" + deg + "deg)"
    	});
    };

    utilsService.getSwurveysLogoURL = function() {
        var backgroundColor = $(".viewer")
            .css("background-color");

        var rgb = backgroundColor.split('(')[1].split(')')[0].split(",");

        var o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);

        if(o > 125) {
            return "images/thece-logo-color.svg";
        } else {
            return "images/thece-logo-color.svg";
        }
    }

	utilsService.convertImageElementToBase64 = function(img, options) {
		var canvas = document.createElement('CANVAS');
		var ctx = canvas.getContext('2d');
		canvas.height = img.height;
		canvas.width = img.width;
		ctx.drawImage(img, 0, 0);

		try {
			var dataURL = canvas.toDataURL(
				options.outputFormat || 'image/jpeg',
				options.outputQuality || 0.92
			);
		} catch(error) {
			console.error(error);
			var dataURL = img.src;
		}
		canvas = null;
		return dataURL;
	}

    utilsService.loadScript = function (src, callback) {

    	var script = document.createElement("script");
    	script.type = "text/javascript";
    	script.src = src;
    	script.async = true;
    	if(callback) script.onload = callback;

    	document.getElementsByTagName("head")[0].appendChild(script);
    };

    utilsService.cleanUpStyle = function (freeStyle, disabled) {
    	if(!freeStyle) {
    		freeStyle = $("style#free-style")[0].sheet;
    	}

    	freeStyle.disabled = disabled;

    	if(freeStyle.cssRules.length > 0) {
    		for(var i = 0; i < freeStyle.cssRules.length; i++) {
    			freeStyle.deleteRule(freeStyle.cssRules[i]);
    		}
    	}
    };
    
    
    utilsService.reloadStyles = function (stylesList) {
		var freeStyle = $("style#free-style")[0].sheet;

		utilsService.cleanUpStyle(freeStyle, false);
		stylesList.forEach(function (style) {
			var index = freeStyle.cssRules.length;
			try {
			  freeStyle.insertRule(style, index);

			}
			catch (e){
				console.warn(style);
				console.warn(e);
			}
		});
		var backgroundColor = $(".viewer").css("background-color");
		$('meta[name="theme-color"]').attr("content", backgroundColor);
    	
    };

    utilsService.CSSArrayFromJSONChildren = function (jsonChildren) {
    	var css = [];
    	for(var key in jsonChildren) {
    		if(jsonChildren.hasOwnProperty(key)) {
    			css.push(key + " { " + CSSJSON.toCSS(jsonChildren[key]) + " }");
    		}
    	}
    	return css;
    };

    utilsService.applyStyleOverrides = function (model) {
        if(model.classOverrides) {
            if(model.classOverrides.add) {
                angular.forEach(model.classOverrides.add, function (value, key) {
                    value.forEach(function (style) {
                        console.log(key, style);
                        console.log($(key));
                        $(key)
                            .addClass(style);
                    });
                });
            }
            if(model.classOverrides.remove) {
                angular.forEach(model.classOverrides.remove, function (value, key) {
                    value.forEach(function (style) {
                        console.log(key, style);
                        $(key)
                            .removeClass(style);
                    });
                });
            }
        }
    };

    utilsService.getAllCardTypes = function (model) {
        var classes = [];
        model.cards.forEach(function (card) {
            if(classes.indexOf(card.type) == -1) {
                classes.push(card.type);
            }
        });
        return classes;
	};

	function stripHTML(html) {
		var tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}

	utilsService.getCardMap = function (model, questionCardTypes) {
		var cards = {}

		for (var i = 0; i < model.cards.length; i++) {
			var card = model.cards[i];

			//Remove html tags
			card.text = stripHTML(card.text);

			if (questionCardTypes.indexOf(card.type) > -1) {

				//Add card to card map
				cards[card.id] = card;

				cards[card.id].visible = false;

				//Add chartType property
				if (cards[card.id].type !== "slider") {
					cards[card.id].chartType = "pie";
				} else {
					cards[card.id].chartType = "bar";
				}
			}
		}

		return cards
	}

    utilsService.makeFeedback = function (cards, data) {
		var responses = data.Responses;
		var res = {};
		var feedback = [];

		if (!cards || !data || data.errorMessage) {
			return 0;
		}

		cards.forEach(function (card, index, array) {
			if (card.id && card && responses && responses[card.id]) {
				var thisCardsResponses = responses[card.id];
				var inverted = _.invert(thisCardsResponses);
				var topResponseRate = _.max(thisCardsResponses);
				var topResponseDirection = inverted[topResponseRate];
				feedback.push({
					question: "<b>" + card.text + "</b>",
					answer: card[topResponseDirection].choice.toString(),
					count: topResponseRate
				});
			}
		}, feedback);

		//Just pick one and put that on the card

		var sampleResponse = _.sample(feedback);

		console.log("Sample Response:", sampleResponse);

		var totalResponseCount = data.TotalResponseCount;

		if (!sampleResponse) {
			sampleResponse = { count: 0 };
		}

		sampleResponse.percentage = ((sampleResponse.count / totalResponseCount) * 100)
			.toFixed(0);

		return sampleResponse;
    };

    utilsService.makeResponse = function (cards) {
    	var response = {};
    	cards.forEach(function (element, index, array) {
    		if(element.answer && element.id) {
    			response[element.id] = element.answer.direction;
    		}
    	}, response);

    	return response;
    };

    utilsService.makeSummary = function (cards, fun, isPreview) {
    	var summaries = [];
    	cards.forEach(function (element, index, array) {
    		if(isPreview && element.sumText) {

    			summaries.push(fun(element.sumText.replace(/===/, "<b>===</b>")));

    		} else if(element.answer && element.sumText) {
    			summaries.push(fun(element.sumText.replace(/===/, "<b>" + element.answer.sumText + "</b>")));
    		}
    	}, summaries);
    	return summaries;
    };

    //Used to generate Swurvey id
    utilsService.generateUUID = function (removeDashes) {
    	var d = new Date()
    		.getTime();
    	var uuid = 'Axxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    		var r = (d + Math.random() * 16) % 16 | 0;
    		d = Math.floor(d / 16);
    		return(c == 'x' ? r : (r & 0x3 | 0x8))
    			.toString(16);
    	});
    	if(removeDashes) {
    		uuid = uuid.replace(/-/g, "");
    	}
    	return uuid;
    };

    utilsService.getQueryParameterByName = function(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    };

    utilsService.gotoDashboard = function (uid) {
        window.location.hash = "#/d/" + uid;
    }

    utilsService.bindEventHandler = function (eventName, handler) {
        window.addEventListener(eventName, handler);
    }

	utilsService.defaultText = {
		leadGen: "Like our content? Enter your email address to join our newsletter.",
		extendedFeedback: "Have more to say? Please leave your comments below.",
		referralCard: "Would you like to signup to make your own microgames?"
	}

	utilsService.getOpenGraphLink = function (swurveyId, userToken) {
		return swurveysStageConfig.getConfigPropertyValue("public_api_endpoint") + "/api/swurveys/" + swurveyId + "/opengraph?userToken=" + userToken;
	}

	utilsService.getNoAuthResponsesLink = function (swurveyId) {
		return swurveysStageConfig.getConfigPropertyValue("api_endpoint") + "/api/swurveys/" + swurveyId + "/responses/noauth";
	}


    //These are valid swipe directions, NOT valid 'choices'
	utilsService.questionTypes = {
		binary: ['left', 'right'],
		three: ['left', 'right', 'bottom'],
		four: ['left', 'right', 'bottom', 'top'],
		slider: ['left', 'right', 'bottom', 'top']
	};

	//These are valid answer choices
	utilsService.cardAnswerKeys = {
		binary: ['left', 'right'],
		three: ['left', 'right', 'bottom'],
		four: ['left', 'right', 'bottom', 'top'],
		slider: ['one', 'two', 'three', 'four', 'five']
	};


	utilsService.getQuestionCardTypes = function () { return Object.keys(utilsService.questionTypes); };


    //Given a model, generate an empty response object.  This is used on the analytics page, and, specifically for billboard preview
	utilsService.generateBlankResponse = function(model) {
		var questionTypes = utilsService.getQuestionCardTypes();

		var responses = {};

		model.cards.forEach(function(card) {

			if(questionTypes.indexOf(card.type) >= 0)
			{
				var response = {};
				switch (card.type) {
					//4 and 3-way cascade down as they are supersets of what's below
					case "four":
					  response.top = 0;
					case "three":
				      response.bottom = 0;
					case "binary":
				      response.left = 0;
				      response.right = 0;
						break;
					case "slider":
					  response.one = 0;
					  response.two = 0;
					  response.three = 0;
					  response.four = 0;
					  response.five = 0;
				}
				responses[card.id] = response;
			}
		});

		return responses;
	}

	utilsService.generateNonBlankResponse = function (model, responseCount) {

		// Generate a blank response first
		var responses = utilsService.generateBlankResponse(model)

		// Randomly add responses
		for (var cardId in responses) {
			var response = responses[cardId]
			var options = Object.keys(response)
			for (var i = 0; i < responseCount; i++) {
				// pick a random option
				var option = options[Math.floor(Math.random() * options.length)]
				// respond
				response[option]++
			}
		}

		return responses
	}

	//Given a swurvey model, generate a sample Response object that exercise all the features of the model
	//This can be used to test webhooks/etc
	utilsService.makeFakeResponseObject = function(model) {
		var response = {
			responses : {},
			metadata : {},
			timeMS: 30000
		};

		model.cards.forEach(function(card){
			//Pick a random answer for each card
			if(card.id && card.type != "content")
     			response.responses[card.id] = _.sample(utilsService.cardAnswerKeys[card.type]);
		});

		if(model.options.leadgen)
		{
			if (model.options.leadgen.enableName) {
				response.metadata.name = "John Doe";
			}
			if (model.options.leadgen.enableEmail) {
				response.metadata.email = "johndoe@gmail.com";
			}
			if (model.options.leadgen.enablePhone) {
				response.metadata.phone = "555-867-5309";
			}
			if (model.options.leadgen.enableCompany) {
				response.metadata.company = "Thece.co, Inc.";
			}
		}

		if (model.options.extendedFeedback && model.options.extendedFeedback.enabled && model.options.extendedFeedback.enableComments) {
			response.metadata.comments = "I really loved this microgame!";
		}
		return response;
	}

	utilsService.normalizeModel = function(model) {
		if(!model.options) model.options = {};
		if(!model.options.action) model.options.action = {};
		if(!model.options.leadgen) model.options.leadgen = {};
		if(!model.options.scoring) model.options.scoring = {};
		if(!model.options.shortURL) model.options.shortURL = {};
		if(!model.options.smsRetrieval) model.options.smsRetrieval = {};
		if(!model.options.extendedFeedback) model.options.extendedFeedback = {};
		if(!model.options.branching) model.options.branching = {};
		if(!model.options.webhook) model.options.webhook = {};
		if(!model.options.customTracking) model.options.customTracking = {fb: {}};
		if(!model.options.referralCard) model.options.referralCard = {};
		return model;

	};
	
	var validChoices = ["one", "two", "three", "four", "five", "six", "seven", "eight"];
	utilsService.validSliderChoices = validChoices;
	utilsService.getOrderedValidSliderChoices = function(card) {
		var intersect = validChoices.filter(function(i) { return Object.keys(card).indexOf(i) >= 0});
		return intersect;
	};
	

	return utilsService;

	}
}());
