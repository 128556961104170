(function () {
    "use strict";

    angular.module("swurveys.common")
        .factory('fontLink', Handler)

    function Handler () {

        var fontLinkService = {};

        fontLinkService.addFontLink = function(font){
            var api = 'https://fonts.googleapis.com/css?family=';
            var link = api + font;
            if ($("link[href*='" + font + "']").length === 0){
                $('link:last').after('<link href="' + link + '" rel="stylesheet" type="text/css">');
            }
        };
        fontLinkService.addFontLinkClass = function(jmcclass) {

            if (jmcclass && jmcclass["attributes"] && jmcclass["attributes"]['font-family']) {
                var fontFamily = jmcclass["attributes"]['font-family'];
                var families = fontFamily.replace(/'/g,"").split(",")
                // A font family string can be multiple cascading families. let's try to preload all
                families.forEach(function(fontName){
                    try {
                        fontLinkService.addFontLink(fontName);
                    }
                    catch(e){
                        console.warn("Error preloading font: ",e);
                    }
                });
            }
        };

        return fontLinkService;
    }

}());