(function () {
  /*
  Swurvey Services
  */
  "use strict";

  angular.module("swurveys.taker")
  	.factory("leapMotionService", Handler)

  function Handler(utilsService) {

    function init(options) {
      console.log("Enabling Leap Motion Support");
			utilsService.loadScript("https://js.leapmotion.com/leap-0.6.4.min.js", function() {
				var leapController = Leap.loop({enableGestures: true}, function(frame) {
					//... handle frame data
				});

				leapController.on("gesture", function(gesture) {
					if(gesture.type == "swipe" && gesture.state == "stop") {
						var state = gesture.state;
						var x = gesture.direction[0];
						var y = gesture.direction[1];
						var z = gesture.direction[2];
						if(options.leapMotionMode == "z") {
							y = z;
						}
						var swipeDirection;

						//Classify swipe as either horizontal or vertical
						var isHorizontal = Math.abs(x) > Math.abs(y);
						//Classify as right-left or up-down
						if(isHorizontal){
							if(x > 0) {
								swipeDirection = "right";
							} else {
								swipeDirection = "left";
							}
						} else { //vertical
							if (y > 0) {
								swipeDirection = "up";
							} else {
								swipeDirection = "down";
							}
						}

            options.onSwipe(swipeDirection);
					}
				});
			});
    }

    return {
      init: init
    }
  }
}());
