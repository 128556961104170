(function () {
	"use strict";

	angular.module("swurveys.common")
		.factory("swipeService", Handler)

	function Handler($timeout, $q) {

		var SWIPE_DURATION = 200,
			SWIPE_STEP_WIDTH = 10,
			DROP_WAIT = 100,
			SWIPE_WAIT = 100;

		var directionMap = {
			up: {
				dy: -200
			},
			down: {
				dy: 200
			},
			left: {
				dx: -200
			},
			right: {
				dx: 200
			}
		}

		var swipe = function (direction) {
			var deferred = $q.defer();

			$timeout(function () {

				var element = angular.element("#card");

				var dir = directionMap[direction];

				element.draggable();

				element.simulate("drag", {
					dx: dir.dx || 0,
					dy: dir.dy || 0,
					interpolation: {
						duration: SWIPE_DURATION,
						stepWidth: SWIPE_STEP_WIDTH
					}
				});

        $timeout(function () {
          element.simulate("drop");
        }, DROP_WAIT).then(deferred.resolve);
			}, SWIPE_WAIT);

			return deferred.promise;
		}

		function swipeUp () {
			return swipe("up");
		}

		function swipeDown () {
			return swipe("down");
		}

		function swipeLeft () {
			return swipe("left");
		}

		function swipeRight () {
			return swipe("right");
		}

		return {
			swipeUp: swipeUp,
			swipeDown: swipeDown,
			swipeLeft: swipeLeft,
			swipeRight: swipeRight
		};
	}
}());
