(function () {
	"use strict";

	angular.module("swurveys.taker")
		.factory("localGoogleFontService", Handler)

	function Handler($q, $http) {
		function getBase64Font(link) {
			return $q(function(resolve, reject) {
				$http.get(link)
					.then(function(response) {
						var contentType = response.headers('content-type');
						var base64Data = 'data:' + contentType + ';charset=utf-8;base64,' + btoa(unescape(encodeURIComponent(response.data)));
						var dataObj = {
							link: link,
							base64: base64Data
						}
						resolve(dataObj);
					})
					.catch(function(error) {
						reject(error);
					});
			});
		}

		function fontLinksToBase64(css) {
			return $q(function(resolve, reject) {
				var fontLinkRegex = new RegExp(/(https?:\/\/(fonts\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})\w/, 'ig');
				var matches = css.match(fontLinkRegex);

				if (matches && matches.length === 1) {
					console.warn("Unable to store font locally beacuse only one font link is available.");
				}

				// BUG: the last url always throws errors when converted to base64 for some reason.
				matches.splice(-1,1);

				var promises = [];

				matches.forEach(function(link) {
					promises.push(getBase64Font(link));
				});

				$q.all(promises)
					.then(function(fontData) {
						fontData.forEach(function(data) {
							css = css.replace(data.link, data.base64);
						});
						resolve(css);
					})
					.catch(function(errors) {
						console.error(errors);
						resovle(css);
					});
			});
		}

		function extractFontUrls(stylesArray) {
			return $q(function(resolve, reject) {
				var googleFontsRegex = new RegExp(/(https?:\/\/(fonts\.)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})\w/, 'ig');
				var stylesText = stylesArray.join('\n');
				var fontUrlMatches = stylesText.match(googleFontsRegex);

				if (!fontUrlMatches) {
					return resolve([]);
				}

				// We are only processing the first google fonts link
				var firstGoogleFontsLink = fontUrlMatches[0];

				$http.get(firstGoogleFontsLink)
					.then(function(response) {
						fontLinksToBase64(response.data)
							.then(function(processedCSS) {
								var fontFaceRegex = new RegExp(/(@font-face {)([\s\S]*?)(})/, 'ig');
								var fontFaceStyles = processedCSS.match(fontFaceRegex);
								resolve(fontFaceStyles);
							})
							.catch(function(error) {
								console.error(error);
								resolve([]);
							});
					})
					.catch(function(error) {
						console.error(error);
						resolve([]);
					});
			});
		}

		return {
			extractFontUrls: extractFontUrls
		}
	}
}());