(function () {
  /*
  Survey Services
  */
  "use strict";

  angular.module("swurveys.common")
  	.factory("networkService", Handler)

  function Handler($http, $q) {

    // Inspiration https://www.kirupa.com/html5/check_if_internet_connection_exists_in_javascript.html

    var configOptions = {
       // file will default to the favicon of current site
      file: window.location.origin + "/favicon.ico"
    }

    function config(configObj) {
      angular.extend(configOptions, configObj);
    }

    function isOnline() {
      var deferred = $q.defer();

      var randomNum = Math.round(Math.random() * 10000);
      var url = configOptions.file + "?rand=" + randomNum;

      $http.head(url).then(function(resp) {
        if (resp.status >= 200 && resp.status < 304) {
          deferred.resolve(true);
        } else {
          deferred.resolve(false);
        }
      }, function(error) {
        deferred.resolve(false);
      });

      return deferred.promise;
    }

    return {
      config: config,
      isOnline: isOnline
    }
  }
}());