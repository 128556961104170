(function() {
    /*
	Swurvey Services
	*/
    "use strict";

    angular.module("swurveys.common").factory("deviceTypeService", Handler)

    function Handler() {

        var isDesktop = function() {
            return false;
        }

        var isSafari = function () {
            // http://stackoverflow.com/questions/7944460/detect-safari-browser
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        };


        var isAndroid = function() {
            return navigator.userAgent.match(/Android/i);
        }
        var isBlackBerry = function() {
            return navigator.userAgent.match(/BlackBerry/i);
        }
        var isiOS = function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        }
        var isOpera = function() {
            return navigator.userAgent.match(/Opera Mini/i);
        }
        var isWindows = function() {
            return navigator.userAgent.match(/IEMobile/i);
        }
        
        var isRumii = function() {
            return navigator.userAgent.match(/rumii/i);
        }
        
        var supportsTouch = function() {
            return "ontouchstart" in document.documentElement;
        }

        var isMobile = function() {
            return (isAndroid() || isBlackBerry() || isiOS() || isOpera() || isWindows() || supportsTouch() || isRumii());
        };

        return {isDesktop: isDesktop, isMobile: isMobile, isSafari: isSafari, supportsTouch : supportsTouch}
    }
}());
